<template>
<div>
  <NavBar
  title="医生账号登录"
  left-arrow
  @click-left="$router.go(-1)"
/>
<vfrom @submit="onSubmit"   >
  <vfield
    v-model="user.username"
    name="username"
    label="用户名"
    required
    placeholder="请输入用户名"
    :rules="[{ required: true, message: '用户名不能为空' }]"
    left-icon="user-o"
  />
    <vfield
            v-model="user.password"
            :type="showornot"
            name="password"
            label="密码"
            required
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
            left-icon="lock"
            clearable
    >
        <template #button>
            <span @click="isShow">
              <vicon name="closed-eye" v-if="show" />
              <vicon name="eye-o" v-else />
            </span>
        </template>
    </vfield>
  <div style="margin: 16px;">
    <Button round block type="primary" :disabled="substatus" native-type="submit" >绑定</Button>
  </div>
</vfrom>
    <br>
<!--    <div style="margin: 16px;">-->
<!--        <Button round block type="info"  to="registerDoctor">没有账号，点此注册</Button>-->
<!--    </div>-->

    <RLFooter/>
</div>

</template>

<script>
import {  Form as vfrom,Field as vfield,Button,Icon as vicon } from 'vant';
import {saveBaseInfo} from '@/api/patient/baseinfo';
import {removeToken, setToken} from "@/utils/auth";
// import {getUser} from "@/api/user";
// import {reSendVerifyCode,verifyCode}  from '@/api/upload';
// import {qCenters} from '@/api/healthcenter';

export default {
  name: 'baseinfo',
  components: {
      vfrom,vfield,Button,vicon
  },
  data(){
    return {
      user:{
          username:'',
          password:'',
        centerId:undefined,
      },
       time: '获取验证码',
       substatus:false,
       hasSendCode:false,
       code:'',
       isClick:true,
       showPicker:false,
       centerName:undefined,
       opts:[],
       centerMap:{},
        minDate: new Date(1950, 0, 1),
        maxDate: new Date(),
        showPickerDate: false,
        currentDate: new Date(),
        showornot: 'password',
        show: true,
    };
  },
  methods:{
      isShow() {
          this.show = !this.show
          if (this.show === true) {
              this.showornot = 'password'
          } else {
              this.showornot = 'text'
          }
      },
      noBomBox() {
          document.activeElement.blur();
      },
      showBeginDate () {
          this.showPickerDate = true
      },
      onCancelDate () {
          this.showPickerDate = false
      },
      onConfirmDate () {
          console.log('date111--',this.value1)  // 数值来自日期组件自定义格式的方法回调
          console.log('date222--',this.value2)
          console.log('date333--',this.value3)
          this.user.birthday = `${this.value1}-${this.value2}-${this.value3}`  // 字符串拼接 结果入2020-07-03
          this.onCancelDate()
      },
      formatter (type, value) {
          if (type === 'year') {
              this.value1 = value   // 可以拿到当前点击的数值
              return `${value}年`
          } else if (type === 'month') {
              this.value2 = value
              return `${value}月`
          }
          this.value3 = value
          return `${value}日`
      },
     onConfirm(value){
       this.user.centerId = value.val;
       this.centerName=value.text;
       this.showPicker = false;
     },
     onSubmit(values) {
        console.log(values)

       var query = this.$route.query;
       if(query.code || this.getQueryString('code')){
         // console.log("code")
         // console.log(query.code)
         // console.log(this.getQueryString('code'))
         //this.$store.commit('updateOpenid','123456');
         this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
         setToken(query.code?query.code : this.getQueryString('code'));
         saveBaseInfo(values).then((response) => {
           console.log(response)
           if(response.code==200 || response.code=="200" ){
             this.$toast.success('操作成功');
           }else{
             this.$toast.fail('操作失败请稍候重试');
           }
         });
       }else{
         removeToken();
       }

    },
    initBaseInfo(){
    //     getBaseInfo({}).then((response) => {
    //       if(response.code==200 ){
    //            this.user.nickName=response.data.nickName;
    //            this.user.idCard=response.data.idCard;
    //            this.user.phone=response.data.phone;
    //            this.user.gender=response.data.gender+'';
    //            this.user.centerId=response.data.centerId;
    //            if(this.user.centerId){
    //              this.centerName=this.centerMap["id_"+this.user.centerId].text;
    //            }
    //       }
    // });
    },

  },
    created() {


    },
}
</script>

<style scoped>


</style>
